<template lang="pug">
Popup
    template(slot="title")
      template(v-if="isEdit")  {{ $t('Global.editData', { field: $t('Yield.header_add') }) }}
      template(v-else) {{ $t('Yield.yield_header_form_title') }}
    template(slot="content")
      .selectType(v-if="!isEdit")
        SelectedButtons(
          v-model="type",
          :data="requestTypeOptions()"
          @input="resetForm"
        )
      form.Form(
        id="yield-header-form",
        @submit.prevent="onSubmitForm"
      )
        .Form-item.required
          label.Form-item-label
            | {{ $t('Dictionary.Store') }}
          .control.form-item-select
            custom-search(
              component-name="yield-header-list"
              name="storeName"
              v-model="form.storeId"
              v-validate="'required|is_not:-1|verify_inventory'"
              :data-vv-as="$t('Dictionary.Store')"
              :inputClass="{ 'is-danger': inventoryValidation && veeErrors.has('storeName') }",
              :on-search="getOptionsStore"
              :options="selectMiniStoreList"
              label="name"
              :loading="isSearchStoreLists"
              :searchAll="true"
              :withDebounce="false"
              @input="inputSelection"
              @resetOptions="resetOptions"
            )

            showValidateError(
              v-show="inventoryValidation"
              :errorName="validationErrorMessage"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Date') }}
          .control.form-item-select
            CustomDatepicker(
              id="yield-header-form-datepicker"
              name="yieldDate",
              :data-vv-as="$t('Dictionary.Date') ",
              v-model="form.yieldDate",
              :class="{ 'is-danger': veeErrors.has(`yieldDate`) }"
              v-validate="'required|verify_inventory'",
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabledStartDate="disableDate")
          p.Form-item-help.is-danger(
            v-show="veeErrors.has('yieldDate')"
          ) {{ veeErrors.first('yieldDate') }}

        .Form-item.required(v-if="type === 0")
          label.Form-item-label {{ $t('Yield.Form_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              component-name="form-yield-detail-stock-item"
              v-model="form.stockItem"
              :data-vv-as="$t('Yield.Form_StockItemName')"
              v-validate="'required|verify_inventory'"
              ref="focusableInput"
              :inputClass="{ 'is-danger': inventoryValidation }"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              :placeholder="$t('Yield.Form_StockItemName_Placeholder')"
              :disabled="isEdit"
              @input="fetchStockItems"
            )

          p.Form-item-help.is-danger(
            v-show="inventoryValidation"
          ) {{ validationErrorMessage }}

        .Form-item.required(v-if="type === 1")
          label.Form-item-label {{ $t('Yield.Form_List') }}
          .control
            custom-search(
              name="list"
              component-name="yield-form-list"
              v-model="form.list"
              :data-vv-as="$t('Yield.Form_List') "
              :class="{ 'is-danger': veeErrors.has('list') }"
              :on-search="getOptionsYieldList"
              :searchAll="true"
              :options="selectOptionsYList"
              label="name"
              :placeholder="$t('Yield.Form_List')"
              isAvailableCondition="isAvailable"
              @input="setListData()"
            )

        .Form-item.required(v-if="type === 0")
          label.Form-item-label
            | {{ $t('Yield.Form_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
              id="select-yield-popup-baseunit"
              name="unitId"
              :disabled="!form.stockItem"
              v-model="form.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('Yield.Form_BaseUnit')"
              :class="{ 'is-danger': veeErrors.has('unitId') }")
              option(
                v-if="isEdit"
                value="-1"
                id="option-yield-popup-baseunit-unselected") {{ $t('Yield.Form_BaseUnit_Unselected') }}
              option(
                v-for="unit in filteredUnitList"
                :key="unit.id"
                :value="unit.id"
                :id="`option-yield-popup-baseunit-${unit.name}`"
              ) {{ unit.name }}

        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Quantity') }}
          .control
            customNumberInput(
              id="yield-detail-form-quantity"
              name="quantity"
              :data-vv-as="$t('Dictionary.Quantity')"
              v-model="form.quantity"
              v-validate="!allowNegativeStock ? {required: true, greater_than:0, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max_value: stockItemInfo && type === 0 ? stockItemInfo.baseUnitQuantity : $getConst('MAX_DIGITS_FOR_QUANTITY')}  : {required: true, greater_than:0, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
              :error="veeErrors.has('quantity')"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

            .Form-item-info(v-if="stockItemInfo.baseUnitQuantity")
              div
                .text {{ $t('Yield.StockOnHand') }}
                .value {{ stockItemInfo.baseUnitQuantity }}

        .Form-item.required(
          id="yield-cost-selection"
        )
          label.Form-item-label
            span {{ $t('Yield.header-popup-cost-selection') }}
          .control.flexbox.wrap.m-top-10
            .selection.flexbox
              CustomRadio(
                :id="`yield-header-popup-average-selection`"
                :label="$t('Yield.header-popup-average-selection')"
                :checked="form.pricingType === 1"
                @change="changePricingType(1)")
            .selection.flexbox
              CustomRadio.m-top-10(
                :id="`yield-header-popup-despatch-selection`"
                :label="$t('Yield.header-popup-despatch-selection')"
                :checked="form.pricingType === 2"
                @change="changePricingType(2)")
            .selection.flexbox
              CustomRadio.m-top-10(
                :id="`yield-header-popup-invoice-selection`"
                :label="$t('Yield.header-popup-invoice-selection')"
                :checked="form.pricingType === 3"
                @change="changePricingType(3)")
          .control.m-top-15(v-if="form.pricingType === 2 || form.pricingType === 3")
            label.Form-item-label {{ form.pricingType === 2 ? $t('Yield.Form_Label_DespatchSelection') : $t('Yield.Form_Label_InvoiceSelection') }}
            custom-search(
              name="pricing"
              component-name="form-yield-pricing"
              v-model="form.pricing"
              :data-vv-as="form.pricingType === 2 ? $t('Yield.Form_Label_DespatchSelection') : $t('Yield.Form_Label_InvoiceSelection')"
              v-validate="'required'"
              :on-search="getPricingSelectionOptions"
              :options="selectPricingSelectionOptions"
              :label="form.pricingType === 2 ? 'despatchNumber' : 'invoiceNumber'"
              :placeholder="form.pricingType === 2 ? $t('Yield.Form_Label_DespatchSelection') : $t('Yield.Form_Label_InvoiceSelection')"
              :inputClass="{ 'is-danger': veeErrors.has('pricing') }"
              :disabled="type === 0 ? !form.stockItem && !form.unitId : !form.list"
            )
          p.Form-item-help.is-danger(
            v-show="veeErrors.has('pricing')"
          ) {{ veeErrors.first('pricing') }}

          .flexbox.m-top-10.font-size-small(v-if="form.pricing")
            p.c-success.font-weight-bold {{ $t('Yield.Form_Pricing_Price') }}
            p.m-left-10.font-weight-normal.c-dark {{ form.pricing.price | formatCurrency }}

        .Form-item.required(v-if="type === 0")
          label.Form-item-label
            | {{ $t('Yield.waste_group') }}
          .control.form-item-select
            Icon.icon-down-arrow(name="icon-down-arrow" v-show="!isLoadingWasteGroup")
            customSelectInput(
              name="wasteGroup"
              :optionData="WasteGroupList"
              v-model="form.wasteGroupId"
              :data-vv-as="$t('Yield.waste_group')"
              :error="veeErrors.has('wasteGroup')"
              v-validate="'required|is_not:-1'"
              id="yield-header-select-waste-group"
              optionIdName="option-waste-popup-group-name"
              :disabled="isLoadingWasteGroup || !form.storeId"
            )

            Button(
              variant="icon loading"
              id="select-waste-from-waste-group-loading"
              type="button",
              tabindex="-1",
              v-show="isLoadingWasteGroup")
              Loading(theme="disable")

            showValidateError(
              v-show="veeErrors.has('wasteGroup')"
              :errorName="veeErrors.first('wasteGroup')"
            )

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        type="submit"
        variant="full"
        id="button-yield-add-header",
        form="yield-header-form",
        :disabled="isCreating || inventoryValidation || isLoadingWasteGroup",
      )
        span(v-show="!isCreating")
          template(v-if="isEdit") {{ $t('Global.editData', { field: $t('Yield.header_add') }) }}
          template(v-else) {{ $t('Global.addData', { field: $t('Yield.header_add') }) }}
        Loading(theme="disable", v-show="isCreating")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import CheckInventoryMixin from '@/mixins/validateInventory'
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'yield-header-form',
  mixins: [CheckInventoryMixin],
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      form: {
        storeId: {
          id: '-1',
          name: ''
        },
        yieldDate: moment().format('YYYY-MM-DD'),
        stockItem: null,
        quantity: null,
        wasteGroupId: '-1',
        unitId: null,
        pricing: null,
        pricingType: 1,
        list: null
      },
      selectOptions: [],
      selectMiniStoreList: [],
      selectPricingSelectionOptions: [],
      disableDate: {
        from: new Date()
      },
      type: 0,
      selectOptionsYList: [],
      isOnlyListUsage: false,
      stockItemInfo: {},
      allowNegativeStock: false
    }
  },
  
  async mounted () {
    if (this.isEdit) {
      if (this.$route.params.item) this.setForm(this.$route.params.item)
      else {
        this.fetchYield(this.$route.params.headerId)
          .then(result => {
            this.setForm(result.data.yieldHeader)
          })
      }
      this.fetchStockItems(this.$route.params.item)
      this.getWasteGroups(false)
    } else {
      this.permissions.find(i => {
        if (i.permission === 221) this.isOnlyListUsage = true
      })
      this.$refs.focusableInput.$refs.search.focus()
      this.form.storeId = { id: this.UserInfo.defaultStoreId, name: this.UserInfo.defaultStoreName } 
      this.getWasteGroups()
    }
  },
  computed: {
    ...mapGetters('Waste', ['WasteGroupList']),
    ...mapGetters('Settings', ['UserInfo', 'permissions']),
    ...mapGetters('Units', ['findUnit', 'getRelatedUnitList']),
    ...mapGetters('Stores', ['storeList']),
    ...mapGetters('Yield', ['Page']),
    validationErrorMessage () {
      return this.veeErrors.has('storeName')
        ? this.veeErrors.first('storeName')
        : this.veeErrors.has('stockItem')
          ? this.veeErrors.first('stockItem')
          : ''
    },

    checkInventoryParams () {
      /*
      *  CheckInventoryMixin için Inventory Validator Parametreleri tanımlandı.
      */
      return {
        storeId: this.form.storeId.id,
        inventoryDate: this.form.yieldDate ? moment(this.form.yieldDate).format('YYYY-MM-DD') : null,
        stockItemIdList: this.type === 0
          ? this.form.stockItem ? [this.form.stockItem.id] : null
          : this.form.list ? [this.form.list.yieldStockItemId] : null
      }
    },

    filteredUnitList () {
      if (!this.form.stockItem) return []
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(this.form.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    isEdit () {
      return this.status === 'edit'
    },

    isCreating () {
      return this.$wait.is(['createYieldHeader', 'updateYieldHeader', 'onSubmitFormYieldHeaderForm'])
    },

    isLoadingWasteGroup () {
      return this.$wait.is(['Get_Waste_Group_All'])
    },
    isSearchStoreLists () {
      return this.$wait.is('fetchStoreByName')
    },
  },
  methods: {
    ...mapActions('Yield', ['createYieldHeader','fetchYieldHeaderAll','fetchYieldDetailAll', 'updateYieldHeader', 'fetchYield']),
    ...mapActions('Stock', ['searchStockItem', 'getStockItem']),
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('Despatch', ['getDespatchPriceFilter', 'getInvoicePriceFilter']),
    ...mapActions('ListsYield', ['searchYieldListHeader']),
    ...mapActions('Waste', ['getWasteGroupAll']),
    ...mapActions('Transfer', ['getStockInfo']),
    ...mapActions('Stores', ['getStore','fetchStoreByName']),

    setForm (item) {
      this.form = {...item}
      this.form.storeId = { id: item.storeId,name: item.storeName }
      this.form.stockItem = {
        id: item.yieldStockItemId,
        name: item.yieldStockItemName,
        stockOnHandQuantity: item.stockOnHandQuantity
      }
      if (this.form.pricingType === 2) {
        this.form.pricing = {
          despatchHeaderId: item.despatchHeaderId,
          despatchNumber: item.despatchNumber,
          price: item.price
        }
      }
      if (this.form.pricingType === 3) {
        this.form.pricing = {
          invoiceId: item.invoiceId,
          invoiceNumber: item.invoiceNumber,
          price: item.price
        }
      }
    },

    resetForm () {
      this.form.stockItem = null
      this.form.wasteGroupId = '-1'
      this.form.unitId = null
      this.form.list = null
      this.form.pricing = null
    },

    requestTypeOptions () {
      if (this.isOnlyListUsage) this.type = 1
      return [
        {
          text: this.$t('Yield.form_type_stock_item'),
          value: 0
        },
        {
          text: this.$t('Yield.form_type_list'),
          value: 1
        }
      ]
    },

    async fetchStockItems (item) {
      await this.getStoreInfo(this.form.storeId.id)
      const payload = {
        storeId: this.form.storeId.id || item.storeId.id,
        stockItemId: this.form.stockItem.id || item.id,
        unitId: this.form.unitId ? this.findUnit(this.form.unitId).baseUnitId : this.findUnit(item.unitId).baseUnitId,
        forDate: moment(new Date()).format('YYYY-MM-DD')
      }
      await this.getStockInfo(payload).then(res => {
        this.stockItemInfo = res.data
      })
    },

    getStoreInfo (storeId) {
      this.getStore(storeId).then(res => {
        this.allowNegativeStock = res.data.store.allowNegativeStock
      })
    },
    getOptionsStore (search) {
      this.fetchStoreByName({name: search})
        .then(res => {
          if (res) {
            this.selectMiniStoreList = res.data.items
          }
        })
    },
    inputSelection (data) {
      if (data.length !== 0) {
        this.form.storeId.id = data.id
        this.form.storeId.name = data.name
      }
      this.getWasteGroups()
    },
    async resetOptions () {
      this.selectMiniStoreList = []
      this.getOptionsStore('')
    },

    changePricingType (type) {
      this.form.pricing = null
      this.form.pricingType = type
    },

    setUnit (unitId) {
      this.form.unitId = unitId
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    getOptions (search) {
      this.searchStockItem({Text: search, types: [1, 2, 3]})
        .then(res => {
          this.selectOptions = res.data.items
        })
    },

    async getWasteGroups (resetWasteGroup = true) {
      if (resetWasteGroup) this.form.wasteGroupId = '-1'
      if (this.form.storeId.id === '-1' || !this.form.storeId) return
      const payload = {
        storeGroupList: [this.storeList.find(item => item.id === this.form.storeId.id).storeGroupId],
        pageSize: 999999999
      }
      await this.getWasteGroupAll(payload)
    },

    getPricingSelectionOptions (search) {
      if (this.form.pricingType === 2) this.despatchPriceFilter(search)
      if (this.form.pricingType === 3) this.invoicePriceFilter(search)
    },

    despatchPriceFilter (search) {
      this.selectPricingSelectionOptions = []
      this.getDespatchPriceFilter({
        StockItemId: this.type === 0 ? this.form.stockItem.id : this.form.list.yieldStockItemId,
        UnitId: this.type === 0 ? this.form.unitId : this.form.list.unitId,
        DespatchNumberText: search
      }).then(res => {
        this.selectPricingSelectionOptions = res.data.despatchPriceList
      })
    },

    invoicePriceFilter (search) {
      this.selectPricingSelectionOptions = []
      this.getInvoicePriceFilter({
        StockItemId: this.type === 0 ? this.form.stockItem.id : this.form.list.yieldStockItemId,
        UnitId: this.type === 0 ? this.form.unitId : this.form.list.unitId,
        InvoiceNumberText: search
      }).then(res => {
        this.selectPricingSelectionOptions = res.data.invoicePriceList
      })
    },

    getOptionsYieldList (search) {
      const params = {
        search,
        storeId: this.form.storeId.id
      }
      this.searchYieldListHeader(params)
        .then(res => {
          this.selectOptionsYList = res.data.yieldListHeaderList
        })
    },

    setListData () {
      this.form.quantity = this.form.list.quantity
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        if (this.isEdit) await this.editHeader()
        else await this.newHeader()
      })
    }, 'onSubmitFormYieldHeaderForm'),

    async newHeader () {
      const payload = getObjectValuesByKey(this.form, ['quantity', 'pricingType'])
      payload.storeId = this.form.storeId.id
      if (this.type === 0) {
        payload.wasteGroupId = this.form.wasteGroupId
        payload.unitId = this.form.unitId
        payload.yieldStockItemId = this.form.stockItem.id
      } else {
        payload.yieldListHeaderId = this.form.list.id
      }
      payload.yieldDate = moment(this.form.yieldDate).format('YYYY-MM-DD')
      if (this.form.pricingType === 2) payload.despatchHeaderId = this.form.pricing.despatchHeaderId
      if (this.form.pricingType === 3) payload.invoiceId = this.form.pricing.invoiceId
      await this.createYieldHeader(payload)
        .then(res => {
          if (!res) return
          this.$emit('showDrafts')
          this.closePopup()
          const message = this.$t('Yield.createSuccessMessage')
          this.notifyShow({ message })
          this.$emit('getYieldHeaderList', res.data.id)
          if (this.type === 0) {
            this.$router.push({
              name: 'NewYieldDetail',
              params: {
                headerId: res.data.id
              }
            })
          }
        })
    },

    async editHeader () {
      const payload = getObjectValuesByKey(this.form, ['id', 'quantity', 'wasteGroupId', 'unitId', 'pricingType'])
      payload.storeId = this.form.storeId.id
      payload.yieldDate = moment(this.form.yieldDate).format('YYYY-MM-DD')
      if (this.form.pricingType === 2) payload.despatchHeaderId = this.form.pricing.despatchHeaderId
      if (this.form.pricingType === 3) payload.invoiceId = this.form.pricing.invoiceId
      await this.updateYieldHeader(payload)
        .then(res => {
          if (!res) return
          const message = this.$t('Yield.editSuccessMessage')
          this.notifyShow({ message })
          this.closePopup()
          this.$emit('getYieldHeaderList')
        })
    }
  },
  watch: {
    async 'form.stockItem' (val) {
      if (!val || this.isEdit) return
      await this.setUnit(val.unitId)
    }
  }
}
</script>

<style lang="scss" scoped>

  .selection {
    width: 100%;
  }
   .Form-item-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    font-size: $font-size-small;

    > div {
      display: flex;
      margin-right: 20px;
      align-items: center;

      select {
        margin-bottom: -2px;
        border: 0;
        background-color: $color-gray;
      }
    }

    .text {
      color: $color-success;
      font-weight: bold;
      margin-right: 6px;
    }

    .value {
      font-weight: $font-weight-bold;
    }

  }
</style>
